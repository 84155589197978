@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;
} */

/* body {
  color: var(--foreground);
  background: var(--background);
  font-family: Helvetica, sans-serif;
} */

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

/* Chat container */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Messages list */
.messages-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #e5ddd5; /* Light background for chat area */
}

/* Message wrapper */
.message {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* User message styling */
.user-message {
  background-color: #ffffff; 
  flex-direction: row;
}

/* Bot message styling */
.bot-message {
  background-color: #ffffff; /* Light gray background for bot messages */
}

/* Avatar */
.avatar {
  width: 40px;
  height: 40px;
  background-color: #c5c5c5;
  color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  margin: 0 10px;
  flex-shrink: 0;
  object-fit: cover;
}

/* Message body */
.message-body {
  max-width: 80%;
}

/* Name of the sender */
.name {
  font-weight: 600; /* Changed from bold to semibold */
  margin-bottom: 5px;
  font-size: 16px; /* Added font size */
}

/* Content of the message */
.content {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  line-height: 1.2; /* Adjust line height for better readability */
  font-size: 16px; /* Added font size */
  font-weight: 400; /* Changed from normal to regular */
}

/* Additional styling for markdown content */
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  margin: 1em 0 0.5em;
  font-weight: bold;
}

.content p {
  margin: 0.5em 0;
}

.content ul,
.content ol {
  margin: 0.5em 0 0.5em 1.5em;
}

.content li {
  margin: 0.25em 0;
}

.content code {
  background-color: #e8e8e8;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.content pre > code {
  display: block;
  padding: 1em;
  overflow-x: auto;
  background-color: #e8e8e8;
  border-radius: 4px;
}

/* Input area */
.input-area {
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
}

.input-area input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 0 0% 14.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.message-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.action-button {
  padding: 0.25rem;
  border-radius: 0.375rem;
  color: #6b7280;
  transition: all 0.2s;
}

.action-button:hover {
  background-color: #f3f4f6;
  color: #374151;
}
